import React, { Fragment } from 'react'
import { ArticleHeader, Border, Copy, Hero, NavItem, Icon, SectionFull, Sidebar, variables } from '@website2018/da-dobsonville';
import { Link } from 'react-router-dom';
import Asides from '../Asides/Asides'
import MetaHeader from '../Meta/MetaHeader'
import SubMenu from '../Nav/SubMenu'
import PersonFooter from './PersonFooter'
import Media from 'react-media'
import Sticky from 'react-stickynode';
import LazyTwitter from '../Twitter/LazyTwitter';
import { noComma } from "../../common/utils";

const renderSocialItem = ({url, icon, color, title}) => {
  return (
    <NavItem
      color={color}
      fs='18pt'
      target="_blank"
      title={title}
      href={url}
    >
      <Icon iconType='bootstrap' name={icon} />
    </NavItem>
  )
}

const Member = (props) => {
  const { member:m, page: p } = props;
  const { colors, fontWeight } = variables;

  return (
    <Fragment>
      <MetaHeader p={m} />
      <Sticky enabled={true} top={"#mainNav"} innerZ="9998">
        <SubMenu items={p.parent && p.parent.children}>
          {p && (
            <NavItem
              fontWeight={fontWeight.medium}
              color={colors.textBase}
              fs={"13px"}
              lh={"16px"}
            >
              <Link to={`/${p.slug}`}>National Leadership</Link>
            </NavItem>
          )}
          {p.children &&
            p.children.length > 0 &&
            p.children.map(c => (
              <NavItem
                fontWeight={fontWeight.medium}
                color={colors.linkBlue}
                fs={"13px"}
                lh={"16px"}
              >
                <Link to={`/${p.slug}/${c.slug}`}>{c.title.rendered}</Link>
              </NavItem>
            ))}
        </SubMenu>
      </Sticky>
      {m.hero && m.hero.background_image && (
        <Hero
          small={true}
          imageUrl={m.hero.background_image.url}
          title={m.hero.headline ? m.hero.headline : ''}
          bg='none'
          bgPosition={m.hero && m.hero.focus_point && noComma(m.hero.focus_point)}
        />
      )}
      <SectionFull mt={"medium"}>
        <div className={"col-lg-7"}>
          <ArticleHeader
            title={m.title.rendered}
            meta={m.role}
          />

          {m.publicRep && m.publicRep.social && (
            <div style={{paddingBottom: 32}}>
              {m.publicRep.social.facebook && renderSocialItem({
                url: m.publicRep.social.facebook,
                color: colors.socialFacebook,
                icon: 'facebook',
                title: `Facebook profile of ${m.title.rendered}`
              })}
              {m.publicRep.social.instagram && renderSocialItem({
                url: m.publicRep.social.instagram,
                color: colors.socialInstagram,
                icon: 'instagram',
                title: `Instagram profile of ${m.title.rendered}`
              })}
              {m.publicRep.social.twitter && renderSocialItem({
                url: m.publicRep.social.twitter,
                color: colors.socialTwitter,
                icon: 'twitter-x',
                title: `X (Twitter) profile of ${m.title.rendered}`
              })}
              {m.publicRep.email && renderSocialItem({
                url: `mailto:${m.publicRep.email}`,
                color: colors.socialEmail,
                icon: 'envelope',
                title: `Email ${m.title.rendered}`
              })}
            </div>
          )}

          { m.content.rendered &&
            <article className="entry">
              <Copy intro html={m.content.rendered} />
            </article>
          }
          <Media query="(min-width: 576px)">
              { matches => matches ? (
                <Border
                  mt={"large"}
                  mb={"large"}
                />
              ) : (
                <Border
                  mt={"small"}
                  mb={"medium"}
                />
            )}
          </Media>
          { m.publicRep ? <PersonFooter p={m.publicRep}/> : null }
        </div>

        <Sidebar>
          {m.publicRep && m.publicRep.social && m.publicRep.social.twitter && (
            <div style={{ paddingTop: 100, marginBottom: 40 }}>
              <LazyTwitter identity={m.publicRep.social.twitter.replace(/https?:\/\/(www\.)?(x|twitter)\.com\//, '')} height={700} />
            </div>
          )}

          { m.asides &&
            <Asides asides={m.asides} />
          }
        </Sidebar>
      </SectionFull>
    </Fragment>
  )
}

export default Member;
